.main-about {
	--mainAboutTopIdent: clamp(32px, (100/1510) * 100vw, 100px);
	transform: translateY(calc(-1 * var(--mainAboutTopIdent)));
	margin-top: 200px;
	margin-bottom: 20px;

	&__body {
		display: flex;
		justify-content: space-between;
	}

	&__title {
		margin-bottom: clamp(24px, (32/1510) * 100vw, 32px);
	}

	&__info {
		font-size: clamp(14px, (18/1510) * 100vw, 18px);
		margin-bottom: clamp(24px, (32/1510) * 100vw, 32px);
		line-height: 160%;
		color: $grey5;
	}

	&__img {
		border-radius: 6px;
		overflow: hidden;
	}

	&__button {
		color: $black;
		font-size: clamp(14px, (18/1510) * 100vw, 18px);
	}

	@media (max-width: 1366px) {
		margin-top: 150px;

		&__body {
			padding-left: 0;
		}
	}

	@media (max-width: 1100px) {
		&__benefits {
			margin-top: 88px;
		}

		&__item {
			padding: 16px 21px;
		}
	}

	@media (max-width: ($tablet + px)) {
		&__title {
			font-size: clamp(22px, (36 /#{$tablet}) * 100vw, 36px);
		}

		&__benefits {
			flex-wrap: wrap;
		}

		&__item {
			width: 100%;
			margin-bottom: 15px;
		}
	}

	@media (max-width: 605px) {
		&__item {
			flex-direction: column;
			flex: 0 0 auto;
			column-gap: 0;
			row-gap: 12px;
		}
	}
}
