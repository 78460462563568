.main-news-priority {
	--mainNewsPriorityWidth: 657px;
	--mainNewsPriorityHeight: 538px;

	width: var(--mainNewsPriorityWidth);
	height: var(--mainNewsPriorityHeight);
	overflow: hidden;

	&__body {
		width: 100%;
		height: 100%;
		cursor: pointer;
		&:hover {
			.button-link {
				--contentButtonLine: 100%;
			}
		}
	}

	&__date {
		display: inline-block;
		color: $grey2;
		margin-bottom: 16px;
		font-size: clamp(14px, (16/768) * 100vw, 16px);
	}

	&__title {
		height: clamp(55px, (70/768) * 100vw, 70px);
		font-size: clamp(16px, (24/768) * 100vw, 24px);
		font-weight: 600;
		color: $black;
		margin-bottom: clamp(16px, (24/768) * 100vw, 24px);

		div {
			@include textTruncateVertical(2);
		}
	}

	&__image {
		position: relative;
		height: 280px;
		width: 280px;
		display: flex;
		margin: 0 auto 24px;
		background-color: transparent;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__description {
		height: 43px;
		font-size: clamp(14px, (16/768) * 100vw, 16px);
		color: $black;
		margin-bottom: clamp(14px, (16/768) * 100vw, 16px);
		overflow: hidden;

		p:first-child {
			@include textTruncateVertical(1);
		}

		p:not(:first-child) {
			display: none;
		}

		img,
		iframe {
			display: none;
		}
	}

	&__button {
		font-size: 16px;
	}

	@media (max-width: 1099px) {
		--mainNewsPriorityWidth: auto;
		--mainNewsPriorityHeight: auto;

		&__image {
			height: clamp(168px, (303/768) * 100vw, 303px);
		}
	}
}
