.main-hero-about-info {
	--mainAboutInfoPaddingBottom: clamp(10px, (44/1510) * 100vw, 44px);
	width: 100%;

	&__body {
		width: 100%;
	}

	&__title {
		font-family: $fontPlayfair;
		color: $black;
		text-transform: uppercase;
		font-size: clamp(22px, (54/1510) * 100vw, 54px);
		margin-bottom: clamp(16px, (32/1510) * 100vw, 32px);
	}

	&__description {
		max-width: 835px;
		font-weight: 400;
		font-size: clamp(14px, (18/1510) * 100vw, 18px);
		color: $black;
		margin-bottom: clamp(25px, (32/1510) * 100vw, 32px);
		cursor: default;
	}

	&__button {
		font-size: clamp(14px, (18/1510) * 100vw, 18px);
		color: $white;
		font-weight: 600;
		background: $black;
		border: 1px solid $black;
		border-radius: 12px;
		padding: 16px 44px;
		opacity: 1;
		transform: scale(1);
		transition: $defaultTransition;

		&:hover {
			opacity: 0.8;
			transform: scale(1.01);
			transition: $defaultTransition;
		}
	}

	@media (max-width: 1575px) {
		&__title {
			transform: translateX(0);
		}
	}

	@media (max-width: 1100px) {
		padding-top: 40px;
	}

	@media (max-width: ($laptop + px)) {
		--mainAboutInfoPaddingBottom: 0;
	}

	@media (max-width: 645px) {
		&__body {
			margin-top: 0;
		}

		&__title {
			margin-bottom: 16px;
		}

		&__description {
			letter-spacing: -0.5px;
		}

		&__button {
			display: block;
			text-align: center;
			width: 100%;
		}
	}
}
