.main-stats {
	width: 100%;

	&__body {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__list {
		flex: 1 0 55%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		list-style: none;
		padding-right: 25px;
	}

	&__item {
		width: 45%;

		p {
			&:first-child {
				font-family: $fontPlayfair;
				font-size: clamp(36px, (64/1510) * 100vw, 64px);
				line-height: 160%;
				text-transform: uppercase;
				margin-bottom: 12px;
			}

			&:last-child {
				font-size: clamp(16px, (22/1510) * 100vw, 22px);
				line-height: 160%;
			}
		}
	}

	&__img {
		flex: 1 0 40%;
		border-radius: 6px;
		overflow: hidden;

		img {
			width: 100%;
			height: auto;
		}
	}

	@media (max-width: ($tablet + px)) {
		&__img {
			display: none;
		}
	}

	@media (max-width: ($mobile + px)) {
		&__item {
			flex: 1 0 100%;
			margin-bottom: 48px;
		}
	}
}
