.up-scroll {
	--upScrollSize: clamp(40px, (80/1510)*100vw, 80px);
	position: fixed;
	right: calc(var(--paddingContainer) - 1px);
	bottom: 0;
	width: calc(var(--upScrollSize) ) ;
	height: calc(var(--upScrollSize) );
	transform: translateY(calc(var(--upScrollSize)));
	transition: transform 0.45s ease;
	background: url(../img/icons/upScroll.svg) no-repeat transparent 0 / contain !important;
	z-index: $zIndexUpScroll;
	mix-blend-mode: difference;

	&.visible {
		transform: translateY(calc(-1 * var(--upScrollSize)));
	}
}
