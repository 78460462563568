.vacancies-offer {
	--vacanciesOfferContactWidth: 45%;

	max-width: 1044px;

	&__title {
		font-size: clamp(16px, (24/768) * 100vw, 24px);
		font-weight: 600;
		margin-bottom: clamp(24px, (64/1510) * 100vw, 64px);
	}

	&__list &__item {
		list-style: disc;

		&::marker {
			font-size: clamp(16px, (22/768) * 100vw, 22px);
			color: $blueLight;
		}
	}

	&__list {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		gap: clamp(5px, (20/1510) * 100vw, 20px) 0;
		flex-wrap: wrap;
		margin-left: 20px;
		margin-bottom: clamp(48px, (64/1099) * 100vw, 64px);
	}

	&__item {
		flex: 0 1 var(--vacanciesOfferContactWidth);
		height: 100%;
		font-size: clamp(14px, (16/768) * 100vw, 16px);
	}

	&__contact {
		display: flex;
		justify-content: space-between;
	}

	&__contact-col {
		width: 65%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;

		&:last-child {
			width: 35%;
			margin-left: 25px;
		}
	}

	&__contact-title {
		font-size: clamp(16px, (18/768) * 100vw, 18px);
		font-weight: 600;
		margin-bottom: clamp(16px, (32/768) * 100vw, 32px);
		text-transform: uppercase;
	}

	&__contact-link {
		display: block;
		padding-bottom: 24px;
		color: #1c2691;
		@include defaultTextUnderline;

		&:hover {
			text-decoration: none;
		}
	}

	&__contact-phone {
		display: flex;
		align-items: center;
		margin-bottom: 16px;

		> a {
			font-size: clamp(14px, (24/768) * 100vw, 24px);
			font-weight: 600;
			color: $black;
			margin-right: 32px;
		}

		> span {
			font-size: clamp(12px, (16/500) * 100vw, 16px);
			color: $greyDark;
		}
	}

	@media (max-width: 924px) {
		&__list,
		&__contact {
			display: block;
		}

		&__title {
			margin-bottom: 24px;
		}

		&__item {
			margin-bottom: 14px;
		}

		&__contact-title {
			margin-bottom: 24px;
		}

		&__contact-phone {
			margin-bottom: 14px;
		}

		&__contact-col {
			width: 100%;

			&:last-child {
				width: 100%;
				margin-left: 0;
			}
		}

		&__contact-col:not(:last-child) {
			margin-bottom: clamp(48px, (64/1099) * 100vw, 64px);
		}
	}

	@media (max-width: 560px) {
		&__contact-phone {
			flex-direction: column;
			align-items: flex-start;
		}
	}
}
