// .contact-social {
// 	--contactSocialSize: 34px;
// 	width: 100%;
// 	height: 100%;
// 	display: flex;

// 	&__item {
// 		flex: 0 0 var(--contactSocialSize);
// 		height: var(--contactSocialSize);

// 		&:not(:last-child) {
// 			margin-right: 16px;
// 		}

// 		&:hover .contact-social__logo {
// 			opacity: 0.65;
// 		}
// 	}

// 	&__link {
// 		display: inline-block;
// 		width: 100%;
// 		height: 100%;
// 	}

// 	&__logo {
// 		width: 100%;
// 		height: 100%;
// 		transition: opacity 0.15s ease;
// 	}
// }

.contact-social {
	--contactSocialSize: 34px;
	width: 100%;
	height: 100%;
	display: flex;

	&__item {
		flex: 0 0 var(--contactSocialSize);
		height: var(--contactSocialSize);

		&:not(:last-child) {
			margin-right: 16px;
		}

		&:hover .contact-social__logo {
			opacity: 0.65;
		}
	}

	&__link {
		display: inline-block;
		width: 100%;
		height: 100%;
	}

	&__logo {
		width: 100%;
		height: 100%;
		transition: opacity 0.15s ease;
	}
}
