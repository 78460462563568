.main-hero {
	--mainHeroEffectHeight: clamp(62px, (113/1510) * 100vw, 113px);
	width: 100%;
	height: 100%;
	margin-top: 5px;

	&__body {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
	}

	&__left {
		flex: 1 0 50%;

		&--padding-r {
			padding-right: 65px;
		}
	}

	&__right {
		flex: 1 0 50%;
		width: 50%;

		&--padding-l {
			padding-left: 65px;
		}
	}

	&__decorative-line {
		position: absolute;
		content: '';
		top: -150px;
		left: -730px;
		width: 676px;
		height: 639px;
		border-bottom: $dashedBorder;
		border-right: $dashedBorder;
		border-bottom-right-radius: 40%;
		transform: rotate(-45deg);
		z-index: 110;
	}

	&__img {
		position: relative;
		z-index: 1;
		border-radius: 6px;

		height: 635px;

		img {
			height: 635px;
			width: 100%;

			object-fit: cover;
		}

		&--shadow {
			box-shadow: 0px 8px 24px rgba(28, 38, 145, 0.1);
		}

		&--dashed-border {
			position: relative;

			&::after {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				left: -36px;
				bottom: -36px;
				border: $dashedBorder;
				border-radius: 5px;
			}
		}
	}

	&__pagination {
		bottom: -10px !important;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		height: 75px;
		padding: 0 30px;
	}

	&__bullet {
		display: inline-block;
		width: 30px;
		height: 7px;
		background: rgba(240, 240, 240, 0.58);
		border-radius: 20px;
		cursor: pointer;
		transition: $defaultTransition;

		&:not(:last-child) {
			margin-right: 8px;
		}

		&--active {
			flex-grow: 0.1;
			background: $white;
			transition: $defaultTransition;
		}
	}

	&__swiper {
		border-radius: 6px;
		height: 635px;
	}

	@media (max-width: 1200px) {
		&__img {
			height: 435px;

			img {
				height: 435px;
			}
		}

		&__swiper {
			height: 435px;
		}
	}

	@media (max-width: ($tablet + px)) {
		&__body {
			&--reverse-mobile {
				flex-direction: column-reverse;
			}
		}

		&__left {
			&--padding-r {
				padding-right: 0;
			}

			&--about-mobile {
				display: none;
			}
		}

		&__right {
			width: 80%;

			&--padding-l {
				padding-left: 0;
			}
		}

		&__img {
			height: 335px;

			img {
				height: 335px;
			}
		}

		&__swiper {
			height: 335px;
		}
	}
}
