.news-inside {
	width: 100%;

	&__body {
		position: relative;
	}

	.title--middle {
		margin-bottom: 0;
	}

	&__date {
		display: block;
		margin-bottom: clamp(24px, 3.17881vw, 48px);
	}

	&__image {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: clamp(24px, (32/1366) * 100vw, 32px);

		img {
			width: 50%;
			height: auto;
		}
	}

	@media (max-width: 768px) {
		&__image {
			img {
				width: 80%;
			}
		}
	}

	@media (max-width: 525px) {
		&__image {
			img {
				width: 100%;
			}
		}
	}
}
