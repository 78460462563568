.news-inside-content {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	width: 100%;
	height: 100%;

	img,
	iframe {
		// height: 317px;
		// width: 453px;
		object-fit: contain;
		margin-right: 10px;
		margin-bottom: 5px;

		&:last-child {
			margin-right: 0;
		}
	}

	@media (max-width: 460px) {
		img,
		iframe {
			height: 228px;
			width: 326px;
		}
	}
}
