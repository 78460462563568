.equipment-slider {
	--mainProjectsHeight: clamp(488px, (761/1510) * 100vw, 761px);
	--slideWidth: clamp(354px, (454/1510) * 100vw, 454px);
	--slideHeght: clamp(420px, (500/1510) * 100vw, 555px);
	--sliderWidth: calc((var(--sliderWidth) * 3) - 48px);

	position: relative;
	width: 90%;
	min-height: var(--mainProjectsHeight);
	user-select: none;

	&__wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	&__header {
		width: 100%;
		height: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: clamp(24px, (64/1510) * 100vw, 64px);
	}

	&__button {
		color: #061d38;
		font-size: clamp(16px, (22/1510) * 100vw, 22px);

		&--mobile {
			display: none;
			font-size: clamp(14px, (16/699) * 100vw, 16px);
			justify-content: flex-start;
			align-self: center;
			white-space: nowrap;
			color: #061d38;
			margin-top: 24px;
		}
	}

	&__title {
		margin-bottom: 0;
		font-size: clamp(36px, (48/1510) * 100vw, 48px);
	}

	&__body {
		--paddingY: clamp(4px, (25/991) * 100vw, 25px);
		position: relative;
		flex: 1 1 auto;
		width: 100%;
		display: flex;
		align-items: center;
		align-self: center;
		height: 362px;
	}

	&__slider {
		display: flex;
		overflow: hidden;
		width: 100%;
		padding: var(--paddingY) 0
			calc((var(--bulletSizeActive) * 2) + var(--paddingY)) 0 !important;
	}

	&__slide {
		flex: 0 0 var(--slideWidth);
		height: var(--slideHeght) !important;
		background-color: transparent;
	}

	&__slide-body {
		box-shadow: none;
		background-color: $white;
		display: flex;
		flex-direction: column;
		max-width: 100%;
		height: 362px;

		img {
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&__navigation {
		position: absolute;
		top: calc(50% - 24px);
		left: 50%;
		transform: translate(-50%, -50%);
		width: calc(100% + 2 * 54px);
		display: flex;
		justify-content: space-between;
		z-index: 79;
	}

	&__next,
	&__prev {
		height: 48px;
		width: 48px;
		border-radius: 50%;
		box-shadow: $defaultShadow;
		background: url(../img/icons/arrowControlSlideHover.svg) $white
			no-repeat center / 24px;
	}

	&__prev {
		transform: rotate(180deg);
	}

	.bullets {
		bottom: calc(-3 * var(--bulletSizeActive)) !important;
	}

	@media (max-width: 1720px) {
		width: 100%;
	}

	@media (max-width: 1366px) {
		&__navigation {
			display: none;
		}
	}

	@media (max-width: 1140px) {
		&__body {
			overflow: visible;
		}
	}

	@media (max-width: 991px) {
		background-color: transparent;
	}

	@media (max-width: ($tablet + px)) {
		--slideWidth: clamp(288px, (354/768) * 100vw, 354px);
		--slideHeght: clamp(325px, (420/768) * 100vw, 420px);
	}

	@media (max-width: 699px) {
		&__button {
			display: none;

			&--mobile {
				display: block;
			}
		}
	}

	@media (max-width: 525px) {
		&__body {
			height: 225px;
		}

		&__slide-body {
			height: 225px;
		}
	}
}
