%breadcrumbsfontStyle {
	font-size: 14px;
	font-weight: 400;
	color: $breadcrumbsColor;
}

.breadcrumbs {
	--breadcrumbsItemGap: 8px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: var(--breadcrumbsItemGap);
	margin-bottom: clamp(16px, (24/1510) * 100vw, 24px);

	li:not(:last-child):after {
		content: '>';
		display: inline-block;
		vertical-align: middle;
		padding-left: var(--breadcrumbsItemGap);
		@extend %breadcrumbsfontStyle;
	}

	li:last-child {
		max-width: 600px;
		@include textTruncateVertical(1);
	}

	a {
		@extend %breadcrumbsfontStyle;
	}

	&.none {
		display: none;
	}

	@media (max-width: 1300px) {
		li:last-child {
			max-width: 400px;
		}
	}

	@media (max-width: 900px) {
		li:last-child {
			max-width: 300px;
		}
	}

	@media (max-width: 600px) {
		li:last-child {
			max-width: 150px;
		}
	}
}
