.about-info-text {
	width: 100%;
	font-size: clamp(14px, (16/1024) * 100vw, 16px);

	&__title {
		color: $black;
	}

	&__wrapper {
		width: 100%;
	}

	p:not(:last-child) {
		margin-bottom: clamp(16px, (24/1024) * 100vw, 24px);
	}
}
