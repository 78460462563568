.footer {
	width: 100%;
	padding: var(--footerPaddingVertical) 0;
	background-color: #1d1d1d;

	&__body {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	&__title {
		margin-bottom: 24px;
	}

	&__contact {
		flex: 0 1 25%;
	}

	&__marketing {
		flex: 0 1 20%;
	}

	&__about,
	&__equipment,
	&__service {
		flex: 0 1 16%;
	}

	&__social {
		--contactSocialSize: 46px;
		margin-top: 28px;
	}

	@media (max-width: 1199px) {
		&__body {
			flex-wrap: wrap;
			justify-content: space-between;
			row-gap: clamp(32px, (64/1199) * 100vw, 64px);
		}

		&__service,
		&__contact,
		&__marketing {
			flex: 0 1 48%;
		}

		&__about,
		&__equipment {
			flex: 0 1 48%;
		}

		&__contact {
			order: 1;
		}

		&__marketing {
			order: 2;
		}

		&__equipment {
			order: 3;
		}

		&__about {
			order: 4;
		}

		&__service {
			order: 5;
		}
	}

	@media (max-width: 680px) {
		&__body {
			row-gap: 0;
		}

		&__marketing,
		&__service,
		&__contact,
		&__about,
		&__equipment {
			flex: 0 1 100%;
			margin-bottom: clamp(32px, (64/1199) * 100vw, 64px);
		}

		&__social {
			display: flex;
			justify-content: center;
			margin-top: 0;
		}
	}
}
