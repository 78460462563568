.news-list {
	--newsListItemHeight: 312px;
	margin-bottom: clamp(24px, (48/1024) * 100vw, 48px);

	&__item {
		display: flex;
		align-items: center;
		gap: 0 clamp(24px, (64/1700) * 100vw, 64px);
		width: 100%;
		border-bottom: 1px solid $greyBorder;
		padding-bottom: clamp(24px, (32/1366) * 100vw, 32px);
		cursor: pointer;
		&:not(:last-child) {
			margin-bottom: clamp(24px, (32/1366) * 100vw, 32px);
		}

		&:last-child {
			border-bottom: none;
		}
		&:hover {
			.button-link {
				--contentButtonLine: 100%;
			}
		}
	}

	&__image {
		flex: 1 0 453px;
		max-width: 453px;
		height: auto;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;

		img {
			width: 185px;
			height: 185px;
			// object-fit: cover;
			object-fit: contain;
		}
	}

	&__content {
		position: relative;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}


	&__date {
		display: inline-block;
		color: $grey2;
		margin-bottom: clamp(14px, (16/1510) * 100vw, 16px);
	}

	&__title {
		@include textTruncateVertical(2);
		font-size: clamp(18px, (28/1366) * 100vw, 28px);
		font-weight: 500;
		color: $black;
		margin-bottom: clamp(18px, (24/1366) * 100vw, 24px);
	}

	&__description {
		max-height: 113px;
		font-size: clamp(14px, (16/1366) * 100vw, 16px);
		margin-bottom: clamp(24px, (32/1366) * 100vw, 32px);
		color: $black;
		font-weight: 400;
		line-height: 160%;
		overflow: hidden;
		@include textTruncateVertical(3);

		p:first-child {
			@include textTruncateVertical(3);
		}

		p:not(:first-child) {
			display: none;
		}

		img,
		iframe {
			display: none;
		}
	}

	&__button {
		font-size: 16px;
		margin-top: auto;
	}

	@media (max-width: 1023px) {
		--newsListItemHeight: auto;

		&__item {
			display: block;
		}

		&__image {
			max-width: 100%;
			width: 100%;

			&:not(:last-child) {
				margin-bottom: 24px;
			}

			img {
				// width: 100%;
			}
		}

		&__button {
			font-size: 16px;
			margin-top: 0;
		}
	}

	@media (max-width: 400px) {
		--newsListItemHeight: auto;

		&__image {
			display: none;

			&:not(:last-child) {
				margin-bottom: 24px;
			}
		}

		&__button {
			font-size: 16px;
			margin-top: 0;
		}
	}
}