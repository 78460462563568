// media
$desktop: 1920;
$laptop: 1440;
$tablet: 768;
$mobile: 360;

:root {
	--fontSize: 16px;
	--headerHeight: clamp(54px, (96/1510) * 100vw, 96px);
	--headerPaddingTop: 14px;
	--footerPaddingVertical: clamp(32px, (82/1024) * 100vw, 82px);
	--contentPaddingTop: clamp(16px, (42/1510) * 100vw, 42px);
	--contentPaddingBottom: clamp(60px, (140/1510) * 100vw, 140px);
	--projectsDropdownListWidth: 398px;
	--backgroundDecorWidth: clamp(40px, (80/1510) * 100vw, 80px);
	--backgroundDecorItemHeigth: clamp(3.5px, (7/1510) * 100vw, 7px);
	--paddingContainer: 55px;
	--containerSpace: 520px;
	--container: calc(
		(
			#{$desktop + px} - (var(--containerSpace) -
						((var(--paddingContainer) * 2)))
		)
	);

	@media (max-width: 1099px) {
		--headerPaddingTop: 0;
	}

	@media (max-width: 991px) {
		--paddingContainer: clamp(16px, (40/991) * 100vw, 40px);
	}

	--bulletSize: clamp(6px, (9 /#{$tablet}) * 100vw, 9px);
	--bulletSizeActive: clamp(8px, (16 /#{$tablet}) * 100vw, 16px);
}

// colors
$black: #000000;
$dark: #1d1d1d;
$white: #ffffff;
$blue: #181c6c;
$blueDark: #061d38;
$blueLight: #1c2691;
$orange: #fcb15a;
$red: #e21e25;
$darkBlue: #061d38;
$grey: #e6e9ed;
$grey2: #9f9f9f;
$grey3: #8e8e8e;
$grey4: #a9a9a9;
$grey5: #444141;
$grey6: #cecece;
$grey7: #d0dbe3;
$grey8: #c0c0c0;
$searchGrey: #f6f6f6;
$greyDark: #2f3233;
$greyBorder: #dddddd;
$greyBorder2: #dfdfdf;
$strokeCard: #181c6c;
$contactTitleColor: #bdbdbd;
$breadcrumbsColor: #aeaeae;
$searchResultTitle: #29166f;

$searchBorder: 1px solid $greyBorder;

// z-index
$zIndexAboutSlider: 1;
$zIndexMainHeroEffect: 79;
$zIndexUpScroll: 80;
$zIndexHeader: 99;
$zIndexContactDropdown: 100;
$zIndexLogoDropdown: 101;
$zIndexProjectsDropdown: 101;
$zIndexContactDropdownClose: 101;

$defaultFontFamily: 'Montserrat', sans-serif;
$fontPlayfair: 'Playfair Display SC', sans-serif;
$fontPlayfair2: 'Playfair Display', sans-serif;
$defaultShadow: 0px 4px 16px rgba(117, 117, 117, 0.12);
$defaultShadowHover: 0px 14px 16px rgba(117, 117, 117, 0.12);

$dashedBorder: 2px dashed $grey4;
$solidGreyBorder: 1px solid $grey8;

$defaultTransition: all 0.3s ease;
$searchFocusAnimation: all 0.3s ease;
