.services {
	width: 100%;

	&__body {
		position: relative;
	}

	&__info {
		margin-bottom: 64px;
	}

	&__text {
		margin-bottom: 16px;

		p {
			margin-bottom: clamp(16px, (24/768)*100vw, 24px);
		}
	}

	&__emphasis {
		font-weight: 600;
	}

	&__projects {
		margin-bottom: 64px;
	}
}
