.about-info {
	--aboutInfoSliderWidth: 302px;
	--aboutInfoSliderHeight: 475px;
	width: 100%;

	&__body {
		position: relative;
		width: 100%;
	}

	&__slider {
		width: var(--aboutInfoSliderWidth);
		height: var(--aboutInfoSliderHeight) !important;
		margin-left: 36px;
		float: right;

		img {
			width: 100%;
			height: auto;
			box-shadow: 0px 4px 20px rgba(81, 81, 81, 0.1);
		}
	}

	&__list {
		display: none;
		justify-content: center;
		column-gap: 24px;
	}

	&__item {
		position: relative;
		width: 50%;
		box-shadow: $defaultShadow;

		img {
			width: 100%;
			height: auto;
		}
	}

	@media (max-width: 991px) {
		&__body {
			flex-direction: column;
			row-gap: 48px;
		}

		&__slider {
			display: none;
		}

		&__list {
			display: flex;
		}

		&__text {
			margin-bottom: clamp(24px, (48/991) * 100vh, 48px);
		}
	}

	@media (max-width: 599px) {
		--aboutInfoSliderWidth: 288px;
		--aboutInfoSliderHeight: 424px;

		&__body {
			position: relative;
			width: 100%;
			display: flex;
		}

		&__slider {
			display: block;
			margin: 0 auto;
			float: none;
			order: 2;
		}

		&__text {
			margin-bottom: 0;
		}

		&__list {
			display: none;
		}
	}
}
