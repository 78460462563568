.vacancies-list {
	--vacanciesListPadding: clamp(24px, (32/1510) * 100vw, 32px);
	--vacanciesItemWidth: 32%;
	--vacanciesItemHeight: clamp(145px, (184/768) * 100vw, 184px);
	display: flex;
	flex-wrap: wrap;
	gap: clamp(24px, (32/1199) * 100vw, 32px) 20px;
	margin-bottom: clamp(60px, (140/1510) * 100vw, 140px);

	&--aside {
		--vacanciesItemWidth: 100%;
	}

	&__item {
		position: relative;
		flex: 0 1 var(--vacanciesItemWidth);
		background-color: $white;
		border: 1px solid rgba($greyBorder, 0.04);
		box-shadow: $defaultShadow;
		min-height: var(--vacanciesItemHeight);
		padding: var(--vacanciesListPadding);
		box-shadow: $defaultShadow;
		color: $black;
		transition: box-shadow 0.4s ease;

		&:hover {
			box-shadow: $defaultShadowHover;
		}

		&:hover > .vacancies-list__arrow-link span {
			width: 30%;
		}
	}

	&__title {
		font-size: clamp(14px, (20/820) * 100vw, 20px);
		font-weight: 600;
		@include textTruncateVertical(3);
	}

	&__arrow-link {
		position: absolute;
		bottom: 16px;
		right: 16px;

		span {
			width: 20%;
			transition: width 0.2s ease;
		}
	}

	@media (max-width: 1199px) {
		--vacanciesItemWidth: 48%;
	}

	@media (max-width: 645px) {
		--vacanciesItemWidth: 100%;
	}
}
