.vacancies-inside {
	overflow: visible;

	&__body {
		display: flex;
		gap: 0 86px;
	}

	&__title {
		margin-bottom: 64px;
	}

	&__aside {
		position: sticky;
		flex: 0 0 453px;
		align-self: flex-start;
		top: calc(var(--headerHeight) + 20px);
		margin-left: auto;
	}

	&__aside-title {
		color: $blueDark;
		margin-bottom: 32px;
	}

	&__content {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		width: 100%;
		height: 100%;

		img,
		iframe {
			height: 270px;
			width: 400px;
			object-fit: cover;
			margin-right: 10px;
			margin-bottom: 5px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	@media (max-width: 460px) {
		&__content {
			img,
			iframe {
				height: 228px;
				width: 326px;
			}
		}
	}

	@media (max-width: 1201px) {
		&__aside {
			display: none;
		}
	}
}
