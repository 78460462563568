.contact-fax {
	margin-top: 15px;
	width: 100%;
	height: 100%;

	&__list {
		width: 100%;
		display: flex;
		column-gap: 24px;
		align-items: center;
	}

	&__item {
		color: $white;
		font-weight: 500;

		&:hover > a {
			opacity: 0.65;
		}

		> a {
			display: flex;
			align-items: center;
			color: $white;
			font-weight: 500;
			transition: opacity 0.15s ease;
		}
	}

	&__icon {
		margin-right: 8px;
	}

	@media (max-width: 991px) {
		&__list {
			column-gap: 24px;
		}

		&__item {
			flex: 0 1 50%;

			&:last-child {
				margin-left: 0;
			}
		}
	}

	@media (max-width: 645px) {
		&__list {
			flex-wrap: wrap;
		}

		&__title {
			margin-bottom: 24px;
		}

		&__item {
			flex: 1 1 100%;

			&:last-child {
				margin-top: 15px;
			}
		}
	}
}
