.table {
 	width: 100%;

	&__row {
		display: flex;
		border-bottom: 1px solid $grey3;

		&:first-child {
			border-top: 1px solid $grey3;
		}
	}

	&__col {
		flex: 1 1 50%;
		min-height: 55px;
		padding: 16px 20px;

		&--title {
			font-weight: 600;
			border-right: 1px solid $grey3;
		}
	}

	@media (max-width: 562px) {
		&__row {
			flex-direction: column;
			padding: 12px 0;
		}

		&__col {
			flex: 1 1 50%;
			min-height: 0;
			padding: 0;

			&--title {
				margin-bottom: 8px;
				border-right: 1px solid transparent;
			}

			&--info {
				font-size: 14px;
			}
		}
	}
}
