.contact {

	&__dropdown {
		position: fixed;
		top: 0;
		right: 0;
		box-shadow: $defaultShadow;
		z-index: $zIndexContactDropdown;

		visibility: hidden;
		transform: translateX(100%);
		transition: all 0.3s ease;

		&.open {
			visibility: visible;
			transform: translateX(0);
			transition: all 0.3s ease;
		}
	}

	&__burger-button {
		display: none;
	}

	@media (max-width: 991px) {
		&__dropdown {
			transform: translate(0, 100%);

			&.open {
				transform: translate(0, 0);
			}
		}

		&__button {
			display: none;
		}

		&__burger-button {
			display: flex;
		}
	}
}
