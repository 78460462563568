.header {
	position: fixed;
	width: 100%;
	background-color: $white;
	z-index: $zIndexHeader;

	&__body {
		width: 100%;
		height: var(--headerHeight);
		display: flex;
		align-items: center;
		border-bottom: 1px solid $greyBorder2;
		padding-top: var(--headerPaddingTop);
	}

	&__logo {
		flex: 0 0 auto;
	}

	&__navigation {
		flex: 1 0 45%;
		margin-right: auto;
		margin-left: clamp(28px, (40 /#{$desktop}) * 100vw, 40px);
	}

	&__search {
		flex: 0 1 0;
		margin-right: 28px;
		transition: $searchFocusAnimation;

		&.focus {
			flex: 0 auto;
			margin-left: 20px;
			transition: $searchFocusAnimation;
			max-width: 310px;
		}
	}

	&__language {
		flex: 0 0 auto;
	}

	@media (max-width: 1215px) {
		&__search {
			&.focus {
				max-width: 210px;
			}
		}
	}

	@media (max-width: 1150px) {
		&__logo {
			margin-right: clamp(5px, (28/991) * 100vw, 24px);
		}

		&__navigation {
			display: none;

			&.hide {
				flex: 1 1 0;
				margin-left: 0;
			}
		}

		&__search {
			flex: 0 1 0;
			margin-right: clamp(16px, (24/991) * 100vw, 24px);
			margin-left: auto;
			transition: $searchFocusAnimation;

			&.focus {
				max-width: none;
				transition: $searchFocusAnimation;
			}
		}
	}

	@media (max-width: 645px) {
		&__language {
			display: none;
		}
	}

	@media (max-width: 450px) {
		&__search {
			&.focus {
				flex: 1 1 50%;
			}
		}
	}
}
