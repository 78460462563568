$focusSearchButton: 3;
$notFocusSearchButton: 2;

.search {
	--heightInput: clamp(32px, (48/1510) * 100vw, 48px);
	--buttonCancelSize: 0;
	position: relative;
	height: var(--heightInput);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;

	&.focus {
		--buttonCancelSize: 20px;

		.search__button {
			background-color: $searchGrey;
			z-index: $focusSearchButton;
		}

		.search__input {
			width: 100%;
			padding-left: 5px;
		}

		.search__input-wrapper {
			width: 100%;
			background-color: $searchGrey;
		}
	}

	&__buttons {
		position: relative;
		width: 40px;
		height: var(--heightInput);
		transition: $searchFocusAnimation;
	}

	&__button,
	&__button-input-focus {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url(../img/icons/search.svg) no-repeat transparent center /
			clamp(18px, (20/1510) * 100vw, 20px);
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		transition: $searchFocusAnimation;
		cursor: pointer;
	}

	&__button-input-focus {
		z-index: $notFocusSearchButton;
	}

	&__input {
		position: relative;
		width: 0%;
		height: var(--heightInput);
		background-color: $searchGrey;
		font-size: clamp(12px, (16/1510) * 100vw, 16px);
		font-weight: 300;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		padding-right: calc(var(--buttonCancelSize) * 2);
		transition: $searchFocusAnimation;
	}

	&__button-cancel {
		position: absolute;
		top: 50%;
		right: calc(var(--buttonCancelSize) / 2);
		transform: translateY(-50%);
		width: var(--buttonCancelSize);
		height: var(--buttonCancelSize);
		background: url(../img/icons/cancelButton.svg) no-repeat transparent 0 /
			clamp(18px, (20/1510) * 100vw, 20px);
	}

	&__input-wrapper {
		position: relative;
		display: flex;
		justify-content: flex-end;
		width: 0%;
		background-color: transparent;
		border-bottom-right-radius: 5px;
		border-top-right-radius: 5px;
		transition: $searchFocusAnimation;
	}

	&__dropdown {
		display: none;
		position: absolute;
		left: -40px;
		top: clamp(50px, (65/1510) * 100vw, 70px);
		width: 140%;
		height: auto;
		background-color: #ffffff;
		border: $searchBorder;

		&--show {
			display: block;
			transition: all 0.5s ease;
		}
	}

	&__item {
		position: relative;
		padding: clamp(14px, (16/1510) * 100vw, 16px)
			clamp(36px, (40/1510) * 100vw, 40px);
		font-size: clamp(12px, (16/1510) * 100vw, 16px);

		a {
			text-decoration: none !important;
			color: $black;
		}

		&:not(:last-child) {
			border-bottom: $searchBorder;
		}

		&:not(:last-child) {
			&:before {
				content: '>';
				color: $black;
				position: absolute;
				left: clamp(16px, (20/1510) * 100vw, 20px);
			}
		}
	}

	&__search-icon {
		position: absolute;
		top: 0;
		left: 0;
		padding: clamp(16px, (20/1510) * 100vw, 20px)
			clamp(11px, (15/1510) * 100vw, 15px);
	}

	@media (max-width: 1150px) {
		width: 100%;

		&.focus {
			.search__input-wrapper {
				width: 50%;
			}
		}
	}

	@media (max-width: 500px) {
		&.focus {
			.search__input-wrapper {
				width: 100%;
			}
		}

		&__dropdown {
			width: 120%;
		}
	}
}
