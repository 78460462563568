.list {
	display: flex;
	list-style-type: inside;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;

	&__sub-list {
	}

	&__item,
	&__sub-item {
		position: relative;

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: "\2022";
			color: $black;
			width: 20px;
		}
	}

	&__item {
		width: 49%;
		margin-bottom: 32px;
	}

	&__name {
		font-size: clamp(16px, (18/1510) * 100vw, 18px);
		margin-left: 12px;
		font-weight: 500;

		&--bold {
			font-weight: 600;
		}
	}

	&__sub-name {
		font-size: clamp(16px, (18/1510) * 100vw, 18px);
		color: $black;
		margin-bottom: 24px;

		&--bold {
			font-weight: 600;
		}
	}

	&__sub-item {
		margin-bottom: 24px;
	}

	&__sub-item-name {
		font-size: clamp(16px, (18/1510) * 100vw, 18px);
		margin-left: 12px;
		font-weight: 400;
	}

	@media (max-width: 525px) {
		&__item {
			width: 100%;
		}
	}
}
