.tabs {
	--tabsPaddingTop: clamp(20px, (52/1920) * 100vw, 52px);
	--tabsContentHeight: clamp(200px, (350/1510) * 100vw, 350px);
	--tabsContentTextHeight: clamp(260px, (315/1510) * 100vw, 315px);
	--tabsContentPaddingLeft: clamp(84px, (135/1510) * 100vw, 135px);
	--navButtonWidth: clamp(148px, (388/1510) * 100vw, 388px);
	--navButtonHeight: clamp(100px, (118/1510) * 100vw, 118px);
	position: relative;
	width: 100%;
	height: 100%;

	&__body {
		width: 100%;
		height: 100%;
	}

	&__nav {
		position: relative;

		display: flex;
		justify-content: space-between;

		&::-webkit-scrollbar {
			display: none;
		}

		&--equipment,
		&--servises {
			flex-wrap: wrap;
		}
	}

	&__nav-button {
		--navButtonPaddingX: 24px;
		position: relative;
		column-gap: 20px;

		min-height: var(--navButtonHeight);

		color: $black;
		font-size: clamp(11px, (16/1024) * 100vw, 16px);
		letter-spacing: 0.5px;
		font-weight: 600;
		text-align: left;
		user-select: none;

		padding: 0 var(--navButtonPaddingX) 0 var(--navButtonPaddingX);

		border: 1px solid transparent;
		box-shadow: 0px 4px 20px rgba(81, 81, 81, 0.1);
		border-bottom-left-radius: clamp(20px, (40/1024) * 100vw, 40px);

		z-index: 1;

		transition: all 0.25s ease;

		&.active {
			background-color: $darkBlue;
			color: $white;
			font-weight: 600;
			border-bottom-left-radius: clamp(20px, (40/1024) * 100vw, 40px);
			transition: color 0.5s ease, border-radius 0.5s ease,
				background-color 0.5s ease;
		}

		&:hover:not(.active) {
			border: 1px solid $blueDark;
			transition: border 0.25s ease;
		}

		&--equipment {
			width: 24%;
		}

		&--services {
			width: 32%;
		}
	}

	&__content {
		position: relative;
		min-height: var(--tabsContentHeight);
	}

	&__content-item {
		display: flex;
		width: 100%;
		position: absolute;
		top: var(--tabsPaddingTop);

		visibility: hidden;
		cursor: default;

		opacity: 0;

		&.active {
			position: relative;
			visibility: visible;

			opacity: 1;
		}
	}

	&__list {
		width: 100%;
	}

	&__description {
		margin-top: 60px;
		font-weight: 400;
		font-size: 18px;
		line-height: 160%;
	}

	@media (max-width: 1024px) {
		--tabsContentTextHeight: 100%;

		&__nav {
			display: flex;
			width: 100%;

			&--equipment {
				width: 100%;
			}
		}

		&__nav-button {
			margin-bottom: 24px;

			&--equipment {
				flex: 0 0 48%;
			}
		}
	}

	@media (max-width: 695px) {
		&__nav {
			flex-wrap: nowrap;
			overflow-x: scroll;
			padding: 20px 0px;
			flex-direction: column;
		}

		&__nav-button {
			height: 55px;
			margin-right: 12px;
			margin-bottom: 0;

			&:last-child {
				margin-right: 0;
			}

			&--equipment,
			&--services {
				flex: 0 0 auto;
				width: auto;
			}

			&.active {
				border-bottom-left-radius: 20px;
			}

			span:last-child {
				@include textTruncateVertical(2);
				padding-bottom: 4px;
			}
		}
	}
}
